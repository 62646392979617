import PropTypes from 'prop-types'
import React from 'react'
import { FaTable } from 'react-icons/fa'

import competencyTracking from '../images/portfolio/competency_tracking.png'
import inductionSystem from '../images/portfolio/induction_system.png'
import trainingRegister from '../images/portfolio/training_register.png'

const imageSwitch = (image) => {
  switch (image) {
    case "competency_tracking":
      return <img src={competencyTracking} alt={image} />
    case "induction_system":
      return <img src={inductionSystem} alt={image} />
    case "training_register":
      return <img src={trainingRegister} alt={image} />
    default:
      break
  }
}

const iconSwitch = (icon) => {
  switch (icon) {
    case "table":
      return <FaTable/>
    default:
      break
  }
}

const GalleryItem = ({ title, description, icon, image }) => (
  <div className="col-md-4 col-sm-6 col-xs-12">
    <div className="open_source_item">
      {
        icon && !image &&
        <div class="icon">
          {iconSwitch(icon)}
        </div>
      }
      {
        image &&
        <div class="image">
          {imageSwitch(image)}
        </div>
      }
      <div className="content">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  </div>
)

GalleryItem.propTypes = {
  siteTitle: PropTypes.string,
}

GalleryItem.defaultProps = {
  siteTitle: ``,
}

export default GalleryItem
