import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Nav from '../components/nav'
import NavCollapsed from '../components/nav_collapsed'

import MainSlider from '../components/main_slider'
import OurMethods from '../components/our_methods'
import OurWorks from '../components/our_works'
import OurServices from '../components/our_services'
import OurPrebuiltSystems from '../components/our_prebuilt_systems'
import OpenSourceSoftware from '../components/open_source_software'
import OurContact from '../components/our_contact'

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navCurrent: "",
      showToTop: false,
      width: 0
    }
    this.scrollWithinComponent = this.scrollWithinComponent.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth })
    window.addEventListener('scroll', this.scrollWithinComponent)
    window.addEventListener("resize", this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollWithinComponent)
    window.removeEventListener("resize", this.updateDimensions)
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth })
  }

  scrollWithinComponent() {
    var home = document.getElementById('home').getBoundingClientRect().bottom
    var method = document.getElementById('method').getBoundingClientRect().bottom
    var work = document.getElementById('work').getBoundingClientRect().bottom
    var service = document.getElementById('service').getBoundingClientRect().bottom
    var prebuilt = document.getElementById('prebuilt').getBoundingClientRect().bottom
    var opensource = document.getElementById('opensource').getBoundingClientRect().bottom
    var contact = document.getElementById('contact').getBoundingClientRect().bottom

    home > 100 ? this.setState({ showToTop: false}) : this.setState({ showToTop: true})

    if (home > 100) {
      this.setState({ navCurrent: "" })
    } else if(method > 100) {
      this.setState({navCurrent: "method"})
    } else if(work > 100) {
      this.setState({ navCurrent: "work" })
    } else if (service > 100) {
      this.setState({ navCurrent: "service" })
    } else if (prebuilt > 100) {
      this.setState({ navCurrent: "prebuilt" })
    } else if (opensource > 100) {
      this.setState({ navCurrent: "opensource" })
    } else if (contact > 100) {
      this.setState({ navCurrent: "contact" })
    } else {
      this.setState({ navCurrent: "" })
    }
  }

  render() {
    return (
      <Layout>
        <SEO keywords={[`gatsby`, `application`, `react`]} />
        {
          this.state.width > 1024
          ? <Nav navCurrent={this.state.navCurrent} showToTop={this.state.showToTop} />
          : <NavCollapsed navCurrent={this.state.navCurrent} showToTop={this.state.showToTop} />
        }
        <MainSlider/>
        <OurMethods/>
        <OurWorks/>
        <OurServices/>
        <OurPrebuiltSystems/>
        <OpenSourceSoftware/>
        <OurContact/>
      </Layout>
    )
  }
}

export default IndexPage
