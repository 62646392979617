import React from 'react'

import GalleryItem from './open_source_gallery_item'
import line from '../images/logo/line-1.png'

class OpenSourceSoftware extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      gallery_items: [
        [
          "Online Induction System",
          "Suited for supplying multimedia inductions to large volumes of trainees",
          "table",
          "induction_system"
        ],
        [
          "Competency Tracking System",
          "Suited for tracking competency requirements for industrial sites",
          "table",
          "competency_tracking"
        ],
        [
          "Training Register",
          "Suited for upgrading excel training matrices on industrial sites",
          "table",
          "training_register"
        ]
      ]
    }
  }

  renderGalleryItems() {
    return (
      <div className="row">
        {
          this.state.gallery_items.map((arr, i) => <GalleryItem key={i} title={arr[0]} description={arr[1]} icon={arr[2]} image={arr[3]} />)
        }
      </div>
    );
  }

  render() {
    return (
      <div className="portfolio_area" id="opensource">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section_title">
                <h2>Open Source <span>Software</span></h2>
                <img src={line} alt="" />

                <p>We have made some of our Systems - Open Source Software. We provide this software at no cost.</p>
                <p>We will only charge for the implementation or modification of the Software.</p>
              </div>
            </div>
          </div>

          { this.renderGalleryItems() }

        </div>
      </div>
    )
  }
}

export default OpenSourceSoftware
